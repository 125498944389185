import axios from 'axios';
import { createBrowserHistory } from 'history';
import {
  deleteLocalData,
  getAccessToken,
  getRefreshToken,
  removeAxiosHeaders,
  serializeParams,
  setAccessToken,
  setAxiosHeaders,
  setRefreshToken
} from '../utils/helper';
import apiEndpoints from './apiEndPoints';

const history = createBrowserHistory({});

let isTokenrefreshing = false,
  retryReqestArray: any = [];

function onTokenRefresh(authorisationToken: any) {
  retryReqestArray.map((cb: any) => cb(authorisationToken));
}

axios.interceptors.response.use(
  (response) => {
    window.console.log(response.status);
    return response;
  },
  (error) => {
    window.console.log(error.Cancel, getAccessToken());
    // if (!error.response && !window.location.href.includes('maintenance')) {
    //   window.location.href = '/maintenance';
    //   return false;
    // }
    if (error && error.response) {
      if (error.response.status === 401) {
        if (getAccessToken()) {
          if (getRefreshToken()) {
            if (!isTokenrefreshing) {
              removeAxiosHeaders();
              isTokenrefreshing = true;
              const params = {
                grant_type: 'refresh_token',
                refresh_token: getRefreshToken(),
              };
              axios
                .post(apiEndpoints.refreshToken, params)
                .then((response) => {
                  setRefreshToken(response.data.refresh_token);
                  setAccessToken(response.data.access_token);
                  setAxiosHeaders();
                  onTokenRefresh(response.data.access_token);
                  window.location.reload();
                })
                .catch(() => {
                  removeAxiosHeaders();
                  deleteLocalData('accessToken');
                  history.push('/');
                  window.location.reload();
                });
            }
            else {
              removeAxiosHeaders();
              deleteLocalData('accessToken');
              history.push('/');
              window.location.reload();
            }
            return new Promise((resolve) => {
              retryReqestArray.push((token: any) => {
                const originalRequest = error.config;
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(axios(originalRequest));
              });
            });
          } else {
            removeAxiosHeaders();
            deleteLocalData('accessToken');
            history.push('/');
            window.location.reload();
          }
        }
      }
      // if (error.response.status === 403) {history.push('/403'); window.location.reload();}
      // if (error.response.status >= 500) {
      //   history.push('/500');
      //   window.location.reload();
      // }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((request: any) => {
  let requestPlatform = 'web';
  try {
  } catch (e) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      requestPlatform = 'm-web';
    }
  }
  request.headers['X-REQUEST-PLATFORM'] = requestPlatform;
  request.headers['X-XSS-Protection'] = '1; mode=block';
  request.headers['X-Frame-Options'] = 'DENY';
  request.headers['X-Content-Type-Options'] = 'nosniff';
  request.headers['Referrer-Policy'] = 'strict-origin-when-cross-origin';
  request.headers['Content-Type'] = 'application/json';
  request.headers['Strict-Transport-Security'] = 'max-age=63072000; includeSubDomains; preload';
  return request;
});

const api = {
  org: {
    stats: () => axios.get(apiEndpoints.stats).then((res) => res.data),
  },
  provider: {
    add: (data: object) => axios.post(apiEndpoints.providerList, data).then((res) => res.data),
    delete: (id: any) => axios.delete(apiEndpoints.providerList + '/' + id).then((res) => res.data),
    update: (id: any, data: object) => axios.patch(apiEndpoints.providerList + '/' + id, data).then((res) => res.data),
  },
  profile: {
    login: (credentials: any) => axios.post(apiEndpoints.authenticate, credentials).then((res) => res.data),
    userData: () => axios.get(apiEndpoints.userData).then((res) => res.data),
    forgotPassword: (data: object) => axios.post(apiEndpoints.forgotPassword, data).then((res) => res.data),
    resetPassword: (data: object) => axios.post(apiEndpoints.resetPassword, data).then((res) => res.data),
    logout: () => axios.post(apiEndpoints.logout).then((res) => res.data),
    // userList: (role?: string) => { role ? axios.get(apiEndpoints.userList + "?role=" + role).then((res) => res.data) : axios.get(apiEndpoints.userList).then((res) => res.data) },
  },
  clientaddition: {
    userList: (role: string) => axios.get(apiEndpoints.userList + '?role=' + role).then((res) => res.data),
    productList: (params?: any) => axios.get(apiEndpoints.products, { params: params }).then((res) => res.data),
    tpaList: () => axios.get(apiEndpoints.tpaList).then((res) => res.data),
    providerList: (params?: any) =>
      axios
        .get(apiEndpoints.providerList, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
        })
        .then((res) => res.data),
    providerListV1: (id?: string) =>
      axios.get(id ? `${apiEndpoints.providerListV1}/${id}` : apiEndpoints.providerListV1).then((res) => res.data),
    addProviderListV1: (data: any) => axios.post(apiEndpoints.providerListV1, (data = data)).then((res) => res.data),
    clientDetails: (data: any) => axios.post(apiEndpoints.clientDetails, (data = data)).then((res) => res.data),
    getClientDetails: (id: any) => axios.get(apiEndpoints.clientDetails + '/' + id).then((res) => res.data),
    updateClientDetails: (data: any, id: any) =>
      axios.patch(apiEndpoints.clientDetails + '/' + id, (data = data)).then((res) => res.data),
    productDetails: (data: any) => axios.post(apiEndpoints.productDetails, (data = data)).then((res) => res.data),
    deleteProduct: (id: any, data: any) =>
      axios.delete(apiEndpoints.productDetails + '/' + id, { data: data }).then((res) => res.data),
    updateClientProducts: (data: any) => axios.post(apiEndpoints.productDetails, (data = data)).then((res) => res.data),
    addClientProducts: (data: any) => axios.post(apiEndpoints.productDetails, (data = data)).then((res) => res.data),
    getClientProducts: (id: any) => axios.get(apiEndpoints.productDetails + '/' + id).then((res) => res.data),
    getClientAllProducts: (id: any, params: any, cancelToken?: any) =>
      axios
        .get(apiEndpoints.productDetails + '/' + id, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    clientList: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.clientList, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    clientListV1: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.clientListV1, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    memberDataReview: (data: any) => axios.post(apiEndpoints.memberDataReview, (data = data)).then((res) => res.data),
    memberDataReviewV1: (data: any) => axios.post(apiEndpoints.memberDataReviewV1, (data = data)).then((res) => res.data),
    endorsementReview: (data: any) => axios.post(apiEndpoints.endorsementReview, (data = data)).then((res) => res.data),
    memberDataSubmit: (data: any) => axios.post(apiEndpoints.memberDataSubmit, (data = data)).then((res) => res.data),
    endorsementDataSubmit: (data: any) => axios.post(apiEndpoints.endorsementUpload, (data = data)).then((res) => res.data),
    bulkMemberDeletion: (data: any) =>
      axios.post(apiEndpoints.bulkMembersDelete, (data = data)).then((res) => res.data),
    downloadMIS: (data: any) => axios.post(apiEndpoints.downloadMIS, (data = data)).then((res) => res.data),
    corporateMIS: (data?: any) => axios.post(apiEndpoints.corporateMIS, (data = data)).then((res) => res.data),
    coverageList: () => axios.get(apiEndpoints.coverageList).then((res) => res.data),
    getTpaInsurerMapping: () => axios.get(apiEndpoints.tpaInsurerMapping).then((res) => res.data),
    setTpaInsurerMapping: (data: any) =>
      axios.post(apiEndpoints.tpaInsurerMapping, (data = data)).then((res) => res.data),
    updateTpaInsurerMapping: (data: any, id: number) =>
      axios.put(apiEndpoints.tpaInsurerMapping + '/' + id, (data = data)).then((res) => res.data),
    getOrgDetails: (params: any) =>
      axios.get(apiEndpoints.organisationDetail, { params: params }).then((res) => res.data),
    getEndorsementProgress: (params: any, cancelToken?: any) =>
      axios.get(apiEndpoints.endorsementProgress, { params: params, cancelToken: cancelToken }).then((res) => res.data),
    getBulkTemplateEmployer: (params: any) =>
      axios.get(apiEndpoints.bulkTemplateEmployer, { params: params }).then((res) => res.data),
    setClientSettingConfig: (data?: any) => axios.post(apiEndpoints.clientSettingConfig, (data = data)).then((res) => res.data),
    getClientSettingConfig: (params?: any) => axios.get(apiEndpoints.clientSettingConfig, { params: params }).then((res) => res.data),
    postClientEndorsementBalanceCheck: (data?: any) => axios.post(apiEndpoints.clientEndorsementBalanceCheck, (data = data)).then((res) => res.data),
    postClientCDBalance: (data?: any) => axios.post(apiEndpoints.rechargeCDBalance, (data = data)).then((res) => res.data),
    getClientProductBatchTransactions: (params?: any) => axios.get(apiEndpoints.clientProductBatchTransactions, { params: params }).then((res) => res.data),
    getClientProductBatchTransactionsSummary: (params?: any) => axios.get(apiEndpoints.clientProductBatchTransactionsSummary, { params: params }).then((res) => res.data),
    updateClientEndorsementBatch: (id?: any, data?: any) => axios.put(apiEndpoints.clientProductEndorsementBatch + '/' + id, (data = data)).then((res) => res.data),
    getClientPolicyTransactions: (params?: any) => axios.get(apiEndpoints.clientTxListing, { params: params }).then((res) => res.data),
    getOrganisationConfig: (params: any) =>
      axios.get(apiEndpoints.organisationConfig, { params: params }).then((res) => res.data),
    getTransactionDetails: (params: any) => axios.get(apiEndpoints.transactionSteps, { params: params }).then((res) => res.data),
    getTransactionMIS: (params: any) => axios.get(apiEndpoints.transactionMIS, { params: params }).then((res) => res.data),
    postTransactionMISReview: (data: any) => axios.post(apiEndpoints.transactionMISReview, (data = data)).then((res) => res.data),
    postTransactionMISSubmit: (data: any) => axios.post(apiEndpoints.transactionMISSubmit, (data = data)).then((res) => res.data),
    getOrganisationOffersAndPlans: (params?: any) => axios.get(apiEndpoints.organisationOffersPlans, { params: params }).then((res) => res.data),
    postOrganisationOffersAndPlans: (data: any) => axios.post(apiEndpoints.organisationOffersPlans, (data = data)).then((res) => res.data),
    getCeleryTaskStatus: (id: string) => axios.get(apiEndpoints.celeryTaskStatus + id).then((res) => res.data),
    postTransactionRecord: (data: any) => axios.post(apiEndpoints.recordTransaction, (data=data)).then((res) => res.data)
  },
  partners: {
    partnerList: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.partnerList, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    addPartnerDetails: (data: any) => axios.post(apiEndpoints.partnerDetails, (data = data)).then((res) => res.data),
    getPartnerDetails: (id: any) => axios.get(apiEndpoints.partnerDetails + '/' + id).then((res) => res.data),
    getPartnerLink: (params: any) => axios.get(apiEndpoints.partnerLink, { params: params }).then((res) => res.data),
    updatePartnerDetails: (data: any, id: any) =>
      axios.patch(apiEndpoints.partnerDetails + '/' + id, (data = data)).then((res) => res.data),
    deleteProduct: (data: any) => axios.post(apiEndpoints.deletePartnerProduct, (data = data)).then((res) => res.data),
    searchPartners: (params: any) => axios.get(apiEndpoints.partnerDataPopulate, { params: params }).then((res) => res.data),
  },
  partner_management: {
    partnerList: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.partnerListing, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    addPartnerDetails: (data: any) => axios.post(apiEndpoints.partnerListing, (data = data)).then((res) => res.data),
    updatePartnerDetails: (data: any, uuid: any) => axios.patch(apiEndpoints.partnerListing + '/' + uuid, (data = data)).then((res) => res.data),
    getPartnerDetails: (uuid: any) => axios.get(apiEndpoints.partnerListing + '/' + uuid).then((res) => res.data),
    getPartnerProducts: (params?: any) => axios.get(apiEndpoints.partnerProducts, { params: params }).then((res) => res.data),
    postPartnerProducts: (data: any) => axios.post(apiEndpoints.partnerProducts, (data = data)).then((res) => res.data),
    choices: (params?: any) => axios.get(apiEndpoints.brokerFilter, { params: params }).then((res) => res.data),
    getBrokerConfig: (params?: any) => axios.get(apiEndpoints.brokerConfig, { params: params }).then((res) => res.data),
    brokerMIS: (data?: any) => axios.post(apiEndpoints.brokerMIS, (data = data)).then((res) => res.data),
    setBrokerSettingConfig: (data?: any) => axios.post(apiEndpoints.brokerSettingConfig, (data = data)).then((res) => res.data),
    getPartnerOffersPlans: (params?: any) => axios.get(apiEndpoints.partnerOffersPlans, { params: params }).then((res) => res.data),
    postPartnerOffersPlans: (data?: any) => axios.post(apiEndpoints.partnerOffersPlans, (data = data)).then((res) => res.data),
  },
  fti: {
    fetchDocsFromPolicyNumber: (data: any, cancelToken?: any) => axios.post(apiEndpoints.fetchManipalDocs,
      data,
      cancelToken
    ).then((res) => res.data),
    getPolicyDocs: (params: object, cancelToken?: any) =>
      axios.get(apiEndpoints.manipalPolicyDocs, { params: params, cancelToken: cancelToken }).then((res) => res.data),
  },
  user_management: {
    createUser: (data: any) => axios.post(apiEndpoints.user, (data = data)).then((res) => res.data),
    addRole: (id: string, roles: any) =>
      axios.patch(apiEndpoints.user + '/' + id, (roles = roles)).then((res) => res.data),
    removeRole: (id: string, roles: any) =>
      axios.patch(apiEndpoints.user + '/' + id, (roles = roles)).then((res) => res.data),
    editRole: (id: string, data: any) =>
      axios.patch(apiEndpoints.editRole + '/' + id, (data = data)).then((res) => res.data),
    updateUser: (data: any, id: number) =>
      axios.put(apiEndpoints.user + '/' + id, (data = data)).then((res) => res.data),
    getRoles: (params?: any) => axios.get(apiEndpoints.getRoles, { params: params }).then((res) => res.data),
    getUserManagementRoles: (params?: any) => axios.get(apiEndpoints.userManagementRoles, { params: params }).then((res) => res.data),
    getUserList: (params: any, cancelToken: any) =>
      axios
        .get(apiEndpoints.user, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    deleteUser: (id: any, params?: any) => axios.delete(apiEndpoints.user + '/' + id, { params: params }).then((res) => res.data),
    checkUserRoles: (id: any, params?: any) => axios.get(apiEndpoints.userDeletion + '/' + id, {
      params: params,
      paramsSerializer: {
        indexes: null,
      },
    }).then((res) => res.data),
    moveUserClients: (data: any) => axios.post(apiEndpoints.userDeletion, data = data).then((res) => res.data),
  },
  member_management: {
    getMemberList: (params: object) =>
      axios
        .get(apiEndpoints.member, {
          params: params,
          paramsSerializer: {
            indexes: null, // no brackets at all
          }
        })
        .then((res) => res.data),
    createMember: (data: any) => axios.post(apiEndpoints.memberV1, (data = data)).then((res) => res.data),
    updateMember: (id: any, data: any) =>
      axios.put(apiEndpoints.member + '/' + id, (data = data)).then((res) => res.data),
    getMember: (params: any) => axios.get(apiEndpoints.member, { params: params }).then((res) => res.data),
    getIndividualMemberData: (params: any) =>
      axios.get(apiEndpoints.individualMemberData, { params: params }).then((res) => res.data),
    requestMemberEcards: (data: any) => axios.post(apiEndpoints.ecards, (data = data)).then((res) => res.data),
    requestMemberEcard: (data: any) => axios.post(apiEndpoints.ecard, data = data).then((res) => res.data),
    requestCertificateOfInsurance: (data: any) =>
      axios.post(apiEndpoints.certificateOfInsurance, (data = data)).then((res) => res.data),
    getSale: (params: object) => axios.get(apiEndpoints.sale, { params: params }).then((res) => res.data),
    deleteMember: (data: any) => axios.post(apiEndpoints.memberRemove, (data = data)).then((res) => res.data),
    editMember: (data: any) => axios.post(apiEndpoints.singleMemberEdit, (data = data)).then((res) => res.data),
    createMemberProduct: (data: any) => axios.post(apiEndpoints.memberProduct, (data = data)).then((res) => res.data),
    getMemberProduct: (params: any) => axios.get(apiEndpoints.singleMemberProducts, { params: params }).then((res) => res.data),
    editMemberProduct: (data: any) => axios.post(apiEndpoints.singleMemberProducts, (data = data)).then((res) => res.data),
    updateMemberStatus: (data: any) =>
      axios.post(apiEndpoints.updateMemberStatus, (data = data)).then((res) => res.data),
    removeDependant: (data: object) => axios.post(apiEndpoints.dependantRemove, data).then((res) => res.data),
    getEndorsements: (params: object) =>
      axios.get(apiEndpoints.endorsements, {
        params: params,
        paramsSerializer: {
          indexes: null,
        },
      }).then((res) => res.data),
    getModificationRequests: (params: object) => axios.get(apiEndpoints.requestsApproval, { params: params }).then((res) => res.data),
    approvalAction: (data: any) => axios.post(apiEndpoints.requestsApproval, (data = data)).then((res) => res.data),
    getEndorsement: (params: object) => axios.get(apiEndpoints.endorsement, { params: params }).then((res) => res.data),
    getEndorsementBatches: (params: object) => axios.get(apiEndpoints.clientEndorsementBatches, { params: params }).then((res) => res.data),
    getClientProductEndorsementPremium: (params: object) => axios.get(apiEndpoints.clientProductEndorsementPremium, { params: params }).then((res) => res.data),
    postEndorsements: (data: any) => axios.post(apiEndpoints.endorsements, (data = data)).then((res) => res.data),
    postEndorsementsForAll: (data: any) => axios.post(apiEndpoints.endorseAll, (data = data)).then((res) => res.data),
    moveEndrosementBatch: (data: any) => axios.post(apiEndpoints.moveEndrosementBatch, (data = data)).then((res) => res.data),
    organisationMIS: (data: any) => axios.post(apiEndpoints.organisationMIS, (data = data)).then((res) => res.data),
    endorsementMIS: (params: any) => axios.get(apiEndpoints.endorsementMIS, { params: params }).then((res) => res.data),
    hardDelete: (data: any) => axios.post(apiEndpoints.hardDeleteMembber, (data = data)).then((res) => res.data),
    makeActive: (data: any) => axios.post(apiEndpoints.makeMemberActive, (data = data)).then((res) => res.data),
    validateEndorsement: (data: any) => axios.post(apiEndpoints.endorsementValidation, (data = data)).then((res) => res.data),
    postEndorsementStatus: (data: any) => axios.post(apiEndpoints.endorsementStatus, (data = data)).then((res) => res.data),
    memberProductUpdate: (data: any) => axios.post(apiEndpoints.memberProductUpdate, (data = data)).then((res) => res.data),
    bulkAssignProducts: (data: any) => axios.post(apiEndpoints.bulkAssignProduct, (data=data)).then((res)=>res.data)
  },
  claims_management: {
    getClaimsList: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.claims, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
        getClaimsListV1: (params: object, cancelToken: any) =>
          axios
            .get(apiEndpoints.claimsv1, {
              params: params,
              paramsSerializer: {
                indexes: null,
              },
              cancelToken: cancelToken,
            })
            .then((res) => res.data),
    populateClaims: (params: object) =>
      axios
        .get(apiEndpoints.populateClaims, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
        })
        .then((res) => res.data),
    populateIndividualClaims: (params: object) =>
      axios
        .get(apiEndpoints.populateIndividualClaims, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
        })
        .then((res) => res.data),
    addClaim: (params: object) => axios.post(apiEndpoints.claims, params).then((res) => res.data),
    pendingClaims: () => axios.get(apiEndpoints.pendingClaims).then((res) => res.data),
    updateClaim: (id: string, params: object) =>
      axios.patch(apiEndpoints.claims + '/' + id, params).then((res) => res.data),
    deleteClaim: (id: string, data: object) => axios.patch(apiEndpoints.claims + '/' + id, data).then((res) => res.data),
    downloadMIS: (params: any) => axios.get(apiEndpoints.downloadClaimsMIS, {
      params: params,
      paramsSerializer: {
        indexes: null,
      },
    }).then((res) => res.data),
    downloadSelectedMIS: (data: any) =>
      axios.post(apiEndpoints.downloadClaimsMIS, (data = data)).then((res) => res.data),
    getDocuments: (params: object) => axios.get(apiEndpoints.claimDocuments, { params: params }).then((res) => res.data),
    postDocuments: (data: any) => axios.post(apiEndpoints.claimDocuments, (data = data)).then((res) => res.data),
    editDocument: (id: number, data: any) => axios.put(apiEndpoints.claimDocuments + '/' + id, (data = data)).then((res) => res.data),
    downloadDocuments: (data: any) => axios.post(apiEndpoints.claimsDocumentDownload, (data = data)).then((res) => res.data),
    getProcessorClaimsDownload: (data: any) => axios.post(apiEndpoints.processorClaimsDocumentDownload, (data = data)).then((res) => res.data),
    fetchCommunication: (params: any, cancelToken: any) => axios.get(apiEndpoints.claimCommunication, {
      params: params,
      cancelToken: cancelToken,
    }).then((res) => res.data),
    sendCommunication: (data: any) => axios.post(apiEndpoints.claimCommunication, (data = data)).then((res) => res.data),
    claimsAnalytics: (params: any) => axios.get(apiEndpoints.claimsAnalytics, {
      params: params,
      paramsSerializer: {
        indexes: null,
      },
    }).then((res) => res.data),
    dateOfAdmissionValidation: (data: any) => axios.post(apiEndpoints.dateOfAdmissionValidation, (data = data)).then((res) => res.data),
    syncClaims: (data: any) => axios.post(apiEndpoints.claimSync, (data=data)).then((res) => res.data),
    claimConfig: (data: any) => axios.post(apiEndpoints.claimConfig, (data=data)).then((res)=>res.data),
  },
  product: {
    getProductBenefits: (params?: any) =>
      axios.get(`${apiEndpoints.productBenefits}`, { params: params }).then((res) => res.data),
    resetProductBenefits: (params?: any) =>
      axios.get(`${apiEndpoints.resetClientProductBenefit}`, { params: params }).then((res) => res.data),
    updateProductBenefitMaster: (id?: string, data?: any) =>
      axios.patch(`${apiEndpoints.productBenefits}/${id}`, (data = data)).then((res) => res.data),
    updateProductBenefitClient: (data?: any) =>
      axios.post(`${apiEndpoints.clientProductBenefit}`, (data = data)).then((res) => res.data),
    setProductBenefits: (data: any) =>
      axios.post(`${apiEndpoints.productBenefits}`, (data = data)).then((res) => res.data),
    setPolicyBenefits: (data: any) =>
      axios.post(`${apiEndpoints.clientPolicyBenefits}`, (data = data)).then((res) => res.data),
    deleteClientPolicyBenefit: (id?: string) =>
      axios.delete(`${apiEndpoints.clientPolicyBenefits}/${id}`).then((res) => res.data),
    deleteProductBenefit: (id?: string) =>
      axios.delete(`${apiEndpoints.productBenefits}/${id}`).then((res) => res.data),
    getProductBenefitTemplates: (id?: string) =>
      axios.get(`${apiEndpoints.productBenefitTemplate}?product_id=${id}`).then((res) => res.data),
    setProductBenefitTemplate: (data?: any) =>
      axios.post(`${apiEndpoints.productBenefitTemplate}`, (data = data)).then((res) => res.data),
    updateProductBenefitTemplate: (id?: string, data?: any) =>
      axios.patch(`${apiEndpoints.productBenefitTemplate}/${id}`, (data = data)).then((res) => res.data),
    getClientBenefits(params: any) {
      return axios.get(`${apiEndpoints.getClientBenefits}`, { params: params }).then((res) => res.data);
    },
    updateBenefitMaster: (id: string, data: object) =>
      axios.patch(`${apiEndpoints.productBenefits}/${id}`, (data = data)).then((res) => res.data),
    setSubscriptionsSteps: (data: any) =>
      axios.post(apiEndpoints.subscriptionBenefits, (data = data)).then((res) => res.data),
    getSubscriptionsSteps: (params: any) =>
      axios.get(apiEndpoints.subscriptionBenefits, { params: params }).then((res) => res.data),
    getProductIntegrationLogs: (params: any) =>
      axios.get(apiEndpoints.productIntegrationLogs, { params: params }).then((res) => res.data),
    activateProduct: (data?: any) =>
      axios.post(`${apiEndpoints.activateProduct}`, (data = data)).then((res) => res.data),
    getProductPlans: (params: any) =>
      axios.get(apiEndpoints.providerProductPlan, { params: params }).then((res) => res.data),
    getProductPlansByUUID: (id: any) =>
      axios.get(apiEndpoints.providerProductPlan + '/' + id).then((res) => res.data),
    addProductPlan: (data: any) =>
      axios.post(apiEndpoints.providerProductPlan, (data = data)).then((res) => res.data),
    updateProductPlan: (id: string, data: any) =>
      axios.patch(apiEndpoints.providerProductPlan + '/' + id, (data = data)).then((res) => res.data),
    getProductList: (id: string) =>
      axios.get(apiEndpoints.products + '/' + id).then((res) => res.data),
    getEndorsementCount: (params: any) =>
      axios.get(apiEndpoints.endorsementCount, { params: params }).then((res) => res.data),
  },
  coupon_management: {
    getCouponCodes: (params: any) => axios.get(apiEndpoints.coupons, { params: params }).then((res) => res.data),
    editCouponCode: (id: any, data: any) =>
      axios.patch(apiEndpoints.coupons + '/' + id, (data = data)).then((res) => res.data),
    getProductDetails: (params: any) =>
      axios.get(apiEndpoints.couponManager, { params: params }).then((res) => res.data),
    delete: (data: any) => axios.post(apiEndpoints.deleteCouponCodes, (data = data)).then((res) => res.data),
    downloadMIS: (params: any) => axios.get(apiEndpoints.downloadCouponMIS, { params: params }).then((res) => res.data),
  },
  individuals: {
    clientList: (params: object, cancelToken: any) =>
      axios
        .get(apiEndpoints.individualList, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    getClientProducts: (id: any, params: any, cancelToken: any) =>
      axios
        .get(apiEndpoints.individualProducts + '/' + id, { params: params, cancelToken: cancelToken })
        .then((res) => res.data),
    updateClientProducts: (data: any, id: number) =>
      axios.put(apiEndpoints.individualProducts + '/' + id, (data = data)).then((res) => res.data),
    getClientDetails: (id: any) => axios.get(apiEndpoints.individualList + '/' + id).then((res) => res.data),
    addNewIndividual: (data: any) => axios.post(apiEndpoints.individualMember, (data = data)).then((res) => res.data),
    getCompanies: (params?: any) =>
      axios.get(apiEndpoints.corporateListing, { params: params }).then((res) => res.data),
    getCompanyMembers: (params: any) =>
      axios.get(apiEndpoints.individualMembers, { params: params }).then((res) => res.data),
    getExistingIndividualDetails: (id: any) =>
      axios.get(`${apiEndpoints.individualMember}/${id}`).then((res) => res.data),
    addIndividualMember: (data: any) =>
      axios.post(apiEndpoints.individualMember, (data = data)).then((res) => res.data),
    addProductToIndividual: (data: any) =>
      axios.post(apiEndpoints.addProductToIndividual, (data = data)).then((res) => res.data),
    removeIndividualDependent: (data: any) =>
      axios.post(apiEndpoints.removeIndividualDependent, (data = data)).then((res) => res.data),
    downloadMIS: (data: any) => axios.post(apiEndpoints.individualMIS, (data = data)).then((res) => res.data),
  },
  choices: {
    getChoices: (params?: any) => axios.get(`${apiEndpoints.choices}`, { params: params }).then((res) => res.data),
    getBookingSource: () => axios.get(apiEndpoints.bookingSource).then((res) => res.data),
    postBookingSource: (data: object) => axios.post(apiEndpoints.bookingSource, data=data).then((res) => res.data),
  },
  communication: {
    getClientCommunicationDetails: (params?: any) =>
      axios.get(`${apiEndpoints.communicationTypes}`, { params: params }).then((res) => res.data),
    getOrganisationDemo: (params: any) =>
      axios.get(`${apiEndpoints.organisationDemo}`, { params: params }).then((res) => res.data),
    updateOrganisationDemo: (data: any) =>
      axios.post(`${apiEndpoints.organisationDemo}`, (data = data)).then((res) => res.data),
    updateCommunicationConsent: (data: any) =>
      axios.post(`${apiEndpoints.communicationConsent}`, (data = data)).then((res) => res.data),
    getMemberCommunication: (params: any) =>
      axios.get(`${apiEndpoints.memberCommunication}`, { params: params }).then((res) => res.data),
    sendCommunicationEmail: (data: any) =>
      axios.post(`${apiEndpoints.communicationEmail}`, (data = data)).then((res) => res.data),
    sendCommunicationEmailToAll: (data: any) =>
      axios.post(`${apiEndpoints.emailToAll}`, (data = data)).then((res) => res.data),
  },
  agent: {
    productList: (params?: any) => axios.get(apiEndpoints.brokerClientProducts, { params: params }).then((res) => res.data),
    showProductList: (params?: any) => axios.get(apiEndpoints.brokerProduct, { params: params }).then((res) => res.data),
    showProviderList: (params?: any) => axios.get(apiEndpoints.brokerProvider, { params: params }).then((res) => res.data),
    createUser: (data: any) => axios.post(apiEndpoints.brokerUsers, (data = data)).then((res) => res.data),
    updateUser: (data: any, id: number) =>
      axios.put(apiEndpoints.brokerUsers + '/' + id, (data = data)).then((res) => res.data),
    getUserList: (params: any, cancelToken: any) =>
      axios
        .get(apiEndpoints.brokerUsers, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    deleteUser: (id: any, params?: any) => axios.delete(apiEndpoints.brokerUsers + '/' + id, { params: params }).then((res) => res.data),
  },
  settings: {
    getNotificationSettings: (params?: any) => axios.get(apiEndpoints.notificationSetting, { params: params }).then((res) => res.data),
    updateNotificationSettings: (id: number, data: any) => axios.put(apiEndpoints.notificationSetting + '/' + id, (data = data)).then((res) => res.data),
  },
  notification: {
    getNotificationList: (params?: any) => axios.get(apiEndpoints.notification, { params: params }).then((res) => res.data),
    updateNotification: (id: number, data: any) => axios.put(apiEndpoints.notification + '/' + id, (data = data)).then((res) => res.data),
    updateAllNotifications: (data: any) => axios.post(apiEndpoints.notification, (data = data)).then((res) => res.data),
    getCount: () => axios.get(apiEndpoints.notificationCount).then((res) => res.data),
  },
  claims_processor: {
    documentList: (params: any) => axios.get(apiEndpoints.claimsProcessorDocumentListing, { params: params }).then((res) => res.data),
  },
  finance: {
    memberWiseMIS: (data: any) => axios.post(apiEndpoints.memberWiseMIS, (data=data)).then((res)=>res.data),},
  offers: {
    listOffers: (params?: any, cancelToken?: any) =>
      axios
        .get(apiEndpoints.offers, {
          params: params,
          paramsSerializer: {
            indexes: null,
          },
          cancelToken: cancelToken,
        })
        .then((res) => res.data),
    addOffer: (data: any) => axios.post(apiEndpoints.offers, (data = data)).then((res) => res.data),
    editOffer: (id: any, data: object) => axios.patch(apiEndpoints.offers + '/' + id, data).then((res) => res.data),
    getOffer: (id: any) => axios.get(apiEndpoints.offers + '/' + id).then((res) => res.data),
    getBrokerOfferPlan: (params?: any) => axios.get(apiEndpoints.brokerOfferPlans, { params: params }).then((res) => res.data),
    setBrokerOfferPlan: (data: any) => axios.post(apiEndpoints.brokerOfferPlans, data).then((res) => res.data),
    offersAndPlansListing: () => axios.get(apiEndpoints.OffersPlans).then((res) => res.data),
    brokerOfferPlanListing: ()=> axios.get(apiEndpoints.brokerPlanOfferList).then((res) => res.data)
  }
};

export default api;
