import { ReloadOutlined } from '@ant-design/icons';
import { Col, Drawer, Row, Spin, Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import coupon from '../../assets/icons/coupon.svg';
import personnel from '../../assets/icons/personnel.svg';
import policy from '../../assets/icons/policy.svg';
import { DEFAULT_PAGE_SIZE } from '../../constants/config';
import { authActions } from '../../store/auth';
import { errorHandler, timeAgo } from '../../utils/helper';
import './_notifications.scss';

// Can use https://github.com/ankeetmaini/react-infinite-scroll-component for infinite scroll

interface Props {
  onClose: any;
  visible?: any;
}
const createMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

const NotificationDrawer: React.FC<Props> = ({ onClose, visible }) => {
  const [notifications, setNotifications] = useState([] as any),
    [loading, setLoading] = useState<boolean>(false),
    [btnLoading, setBtnLoading] = useState<boolean>(false),
    [params, setParams] = useState({
      limit: parseInt(DEFAULT_PAGE_SIZE),
      offset: parseInt('0'),
    }) as any,
    [hasMore, setHasMore] = useState<boolean>(true),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    markAllAsRead = () => {
      changeReadStatus(true);
      setNotifications((prev: any) => {
        return prev?.map((notification: any) => {
          return {
            ...notification,
            is_read: true
          }
        })
      })
    }, getNotifications = () => {
      if (notifications?.length === 0) {
        setLoading(true);
      }
      else {
        setBtnLoading(true);
      }
      api.notification.getNotificationList(params)
        .then((res) => {
          setBtnLoading(false);
          if (notifications?.length > 0) {
            setNotifications((prev: any) => {
              return [...prev, ...res?.data?.results];
            });
          }
          else {
            setNotifications(res?.data?.results);
            setLoading(false);
          }
          setHasMore(res?.data?.count > (params?.limit + params?.offset));
        })
        .catch((err) => { errorHandler(err?.response) });
    }, changeReadStatus = (is_read: boolean, id?: any) => {
      if (id) {
        api.notification.updateNotification(id, { is_read: is_read })
          .then((res) => {
            dispatch(
              authActions.setNotification({
                notification_count: res?.data?.notification_count,
              }));
            setNotifications((prev: any) => {
              return prev?.map((notification: any) => {
                if (notification?.id === id) {
                  return {
                    ...notification,
                    is_read: !notification.is_read
                  }
                }
                return notification;
              })
            })
          })
          .catch((err) => {
            errorHandler(err?.response)
          });
      }
      else {
        api.notification.updateAllNotifications({ is_read: is_read, update_all: true })
          .then((res) => {
            dispatch(
              authActions.setNotification({
                notification_count: 0,
              }))
            setNotifications((prev: any) => {
              return prev?.map((notification: any) => {
                if (notification?.id === id) {
                  return {
                    ...notification,
                    is_read: !notification.is_read
                  }
                }
                return notification;
              })
            })
          })
          .catch((err) => {
            errorHandler(err?.response)
          });
      }
    }, redirectTo = (notification: any) => {
      switch (notification?.subscription?.notification_type) {
        case "MEMBER_ADDITION":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?tab=endorsements&limit=10&offset=0&type=ADDITION&info=${notification?.message}`);
          break;

        case "MEMBER_DELETION":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?tab=endorsements&limit=10&offset=0&type=DELETION&info=${notification?.message}`);
          break;

        case "MEMBER_MODIFICATION":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?tab=endorsements&limit=10&offset=0&type=MODIFICATION&info=${notification?.message}`);
          break;

        case "ENDORSEMENT_DELETION":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?tab=endorsements&limit=10&offset=0&type=DELETION&info=${notification?.message}`);
          break;

        case "ENDORSEMENT_MODIFICATION":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?tab=endorsements&limit=10&offset=0&type=MODIFICATION&info=${notification?.message}`);
          break;

        case "CORPORATE_POLICY_EXPIRY":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/policies-and-products&info=${notification?.message}`);
          break;

        case "RETAIL_CUSTOMER":
          navigate(`/client-manager/individual/${notification?.meta_deta?.user_id}/policies-and-products&info=${notification?.message}`);
          break;

        case "COUPON_EXPIRY":
          navigate(`/products/${notification?.meta_deta?.product_id}/plans/${notification?.meta_deta?.plan_uuid}?limit=10&offset=0&info=${notification?.message}`);
          break;

        case "CD_BALANCE_LOW_THRESHOLD":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?batch=${notification?.meta_deta?.batch_id}&tab=endorsements&limit=10&offset=0`);
          break;

        case "ENDORSEMENTS_BATCH_REMINDER":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?batch=${notification?.meta_deta?.batch_id}&tab=endorsements&limit=10&offset=0`);
          break;


        case "ENDORSEMENTS_BATCH_CLOSED":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?batch=${notification?.meta_deta?.batch_id}&tab=endorsements&limit=10&offset=0`);
          break;


        case "ENDORSEMENT_BATCH_COMPLETION":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?batch=${notification?.meta_deta?.batch_id}&tab=endorsements&limit=10&offset=0`);
          break;


        case "ENDORSEMENT_MARKED_DISCREPANCY":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/member-manager?batch=${notification?.meta_deta?.batch_id}&tab=endorsements&limit=10&offset=0`);
          break;

        case "CLIENT":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}`);
          break;

        case "ENDORSEMENT_MARKED_DISCREPANCY":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/policies-and-products/${notification?.meta_deta?.product_id}/policy/transactions`);
          break;

        case "ENDORSEMENT_UNMARKED_DISCREPANCY":
          navigate(`/client-manager/corporate/${notification?.meta_deta?.organisation_id}/policies-and-products/${notification?.meta_deta?.product_id}/policy/transactions`);
          break;

        default:
          break;
      }
      if (!notification?.is_read) {
        changeReadStatus(true, notification?.id);
      }
      onClose();
    },
    onScrollToBottom = () => {
      setParams((prev: any) => {
        return {
          ...prev,
          offset: prev.offset + parseInt(prev.limit)
        }
      })
    },
    updateNotificationCount = () => {
      api.notification.getCount()
        .then((res) => {
          dispatch(
            authActions.setNotification({
              notification_count: res?.data?.notification_count,
            }));
        })
        .catch((err) => { errorHandler(err?.response) });
    },
    resetNotifcationCount = () => {
      setBtnLoading(true);
      updateNotificationCount();
      setParams({
        limit: parseInt(DEFAULT_PAGE_SIZE),
        offset: parseInt('0'),
      });
      setNotifications([]);
    },
    imageFromType = (type: string) => {
      let policyNotifications = ['CORPORATE_POLICY_EXPIRY', 'RETAIL_CUSTOMER'];
      let personnelNotifications = ['MEMBER_ADDITION', 'MEMBER_DELETION', 'MEMBER_MODIFICATION', 'ENDORSEMENT_DELETION', 'ENDORSEMENT_MODIFICATION'];
      let couponNotifications = ['COUPON_EXPIRY'];
      if (policyNotifications?.includes(type)) {
        return policy;
      }
      else if (personnelNotifications?.includes(type)) {
        return personnel;
      }
      else if (couponNotifications?.includes(type)) {
        return coupon;
      };
      return policy;
    };

  useEffect(() => {
    getNotifications();
  }, [params]);

  useEffect(() => {
    updateNotificationCount();
  }, []);

  return (
    <Drawer
      className='notification-drawer'
      title={
        <div>
          Notifications&nbsp;&nbsp;
        </div>
      }
      // placement="right"
      onClose={onClose}
      destroyOnClose
      visible={visible}
      width={420}
      
      closable={false}
      extra={
        < Row align='middle' gutter={22} >
          <Col>
            <span className="cta" onClick={markAllAsRead}>
              Mark all as read
            </span>
          </Col>
          <Col>
            <ReloadOutlined spin={btnLoading} onClick={resetNotifcationCount} />
          </Col>
        </Row >
      }
    >
      <div id="scrollable" style={{ height: '100%', overflow: "auto" }}>
        <InfiniteScroll
          dataLength={notifications?.length}
          next={onScrollToBottom}
          hasMore={hasMore}
          refreshFunction={resetNotifcationCount}
          loader={
            <div className='loader-div' style={{ height: "200px" }}>
              <div className="loader">
                <Spin size="large" />
              </div>
            </div>
          }
          scrollableTarget="scrollable"
          endMessage={
            <p style={{ textAlign: 'center', marginTop: 20 }}>
              <b>That's all folks!</b>
            </p>
          }
        >
          {!loading && notifications?.length > 0 && notifications?.map((notification: any) => {
            return (
              <div key={notification?.id} className={`notification-item cta ${!notification?.is_read && "unread"}`} onClick={() => {
                if (notification?.subscription?.meta_deta?.redirect_required === true) {
                  redirectTo(notification)
                }
              }}>
                <Row gutter={16} justify="space-between">
                  <Col>
                    {/* <div className="table-cta-small-btn" > */}
                    <div>
                      <img src={imageFromType(notification?.subscription?.notification_type)} width={32} alt="icon" />
                    </div>
                    {/* </div> */}
                  </Col>
                  <Col>
                    <div className="title">{notification?.title}</div>

                    <div
                      className="description"
                      dangerouslySetInnerHTML={createMarkup(notification?.message)}
                    ></div>

                    {/* <div className="description">{notification?.message}</div> */}
                    <div onClick={(e) => e.stopPropagation()}>
                      <div>
                        {!notification?.is_read && <span className="action-cta" onClick={() => { changeReadStatus(true, notification?.id) }}>Mark as read</span>}
                        {notification?.is_read && <span className="action-cta" onClick={() => { changeReadStatus(false, notification?.id) }}>Mark as unread</span>}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Tooltip title={moment(notification?.created_at).format("DD MMM, hh:MM a")}>
                      <div className="timestamp">{timeAgo(moment(notification?.created_at))}</div>
                    </Tooltip>
                  </Col>
                </Row>
              </div>
            )
          })}
        </InfiniteScroll>
      </div>
    </Drawer >
  );
};

export default NotificationDrawer;
