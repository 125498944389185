import { Drawer, Form, Input, Row } from 'antd';
import React from 'react';
import { COUNTRY_CODE } from '../../../constants/config';
import { Button } from '../../../components/UI/Button/Button';
import { useParams } from 'react-router-dom';

interface Props {
  onClose: any;
  visible: any;
  initialValue: any;
  updateHandler: any;
}

const EditRow: React.FC<Props> = ({ onClose, visible, initialValue, updateHandler }) => {
  const [form] = Form.useForm(),
    { claimid } = useParams(),
    handleFormSubmit = () => {
      form.validateFields().then((values) => {
        updateHandler(claimid, values, 'Personal Contact details updated successfully');
        onClose();
      });
    };

  return (
    <Drawer
      title="Edit Claim Details"
      placement="right"
      onClose={onClose}
      destroyOnClose={true}
      visible={visible}
      
      closable={false}
    >
      <Form form={form} name="claim-details" initialValues={initialValue} scrollToFirstError={true}>
        <Form.Item
          label="Personal Email"
          labelCol={{ span: 24 }}
          name="personal_email"
          validateTrigger="onBlur"
          validateFirst
          rules={[{ type: 'email', message: 'Please enter valid email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Personal Phone"
          labelCol={{ span: 24 }}
          name="personal_phone"
          validateTrigger="onBlur"
          validateFirst
          rules={[
            { pattern: RegExp('[6-9]{1}[0-9]{9}'), message: 'Please enter valid mobile number' },
            { len: 10, message: 'Phone number should be 10 character long' },
          ]}
        >
          <Input addonBefore={COUNTRY_CODE} maxLength={10} className="w-addon" />
        </Form.Item>
        <Row>
          <Button type="primary" onClick={handleFormSubmit}>
            Save
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditRow;
